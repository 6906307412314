body {
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #090e19,
    #0c1222,
    #0f172a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #090e19,
    #0c1222,
    #0f172a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* External link indicator */

header a::after,
#gallery a::after,
#gallery a[href="https://canyouteach.me"]::after
{
  content: url("./img/external.svg");
  display: inline-block;
  width: 1rem;
  height: auto;
  padding-left: 0.2rem;
  color: #fff;
}
