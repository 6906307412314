body {
  background: #0f2027;
  background: -webkit-linear-gradient(to bottom, #090e19, #0c1222, #0f172a);
  background: linear-gradient(#090e19, #0c1222, #0f172a);
}

header a:after, #gallery a:after, #gallery a[href="https://canyouteach.me"]:after {
  content: url("external.a42432ea.svg");
  width: 1rem;
  height: auto;
  color: #fff;
  padding-left: .2rem;
  display: inline-block;
}

/*# sourceMappingURL=index.f0e0c38b.css.map */
